<!--
 * @Author: Dyl
 * @Date: 2022-01-11 16:27:41
 * @LastEditTime: 2022-12-14 16:02:09
 * @LastEditors: Please set LastEditors
 * @Description: 首页
 * @FilePath: \Coding-official\src\views\home\index.vue
-->
<template>
  <div class="home" id="home">
    <!-- 导航栏 -->
    <Nav @getType="getType" :isHome="true" :type="type"></Nav>
    <!-- 首页轮播图 -->
    <section>
      <el-carousel class="swiper" height="100%" :interval="3000" arrow="always">
        <el-carousel-item v-for="(item, index) in swiperList" :key="index">
          <img class="swiper-img" :src="item.imgSrc" alt="">
        </el-carousel-item>
      </el-carousel>
    </section>
    <!-- 产品 -->
    <Product id="product"></Product>
    <!-- 解决方案 -->
    <Solve id="solve"></Solve>
    <!-- 应用案例 -->
    <!-- <Application id="application"></Application> -->
    <!-- 核心技术 -->
    <Core id="core"></Core>
    <!-- 合作伙伴 -->
    <Parthers></Parthers>
    <!-- 联系我们 -->
    <Contact></Contact>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from '@/components/nav.vue'
import Product from './components/product.vue'
import Solve from './components/solve.vue'
// import Application from './components/application.vue'
import Core from './components/core.vue'
import Parthers from './components/partners.vue'
import Contact from './components/contact.vue'
import Footer from '../../components/footer.vue'

export default {
  data() {
    return {
      swiperList: [{
        imgSrc: require('@/assets/images/home/swiperOne.png')
      }, {
        imgSrc: require('@/assets/images/home/swiperTwo.png')
      }],
      type: ''
    }
  },
  components: {
    Nav,
    Product,
    Solve,
    // Application,
    Core,
    Parthers,
    Contact,
    Footer
  },
  methods: {
    getType(type) {
      if (type == 'about') {
        this.$router.push('/about')
      } else if (type == 'join') {
        this.$router.push('/join')
      } else {
        let el = document.getElementById(type);
        el.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
      }

    }
  },
  mounted() {
    if (window.localStorage.getItem('navType')) {
      this.type = window.localStorage.getItem('navType')
      let el = document.getElementById(this.type);
      el.scrollIntoView({
        block: "start",
        behavior: "smooth",
        inline: "nearest"
      })
      window.localStorage.removeItem('navType')
    } else {
      window.onload = () => {
        setTimeout(() => {
          this.$nextTick(() => {
            let el = document.getElementById('home');
            el.scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "nearest"
            })
            this.$forceUpdate()
          })
        }, 300)
      }

    }
  },
}
</script>
<style lang="scss" scoped>
* {
  font-family: "思源黑体";
}

.home {
  padding-top: 80px;
  width: 100vw;
}

.swiper {
  width: 100vw;
  height: 33.8vw;
  overflow: hidden;

  .swiper-img {
    width: 100vw;
    height: 100%;
  }
}

/deep/.el-carousel__container {
  height: 650px;
}

/deep/.el-icon-arrow-left {
  background-color: transparent;
  color: #F09C9B;
}

/deep/.el-icon-arrow-right {
  background-color: transparent;
  color: #F09C9B;
}

/deep/.el-carousel__arrow {
  background-color: transparent;

  i {
    font-size: 36px;
  }
}

.swiper-pagination {
  --swiper-theme-color: #c8c8c8;
  --swiper-pagination-color: #ffffff;
}
</style>