<template>
  <div class="solve">
    <div class="solve-bg"></div>
    <div class="solve-header">
      <div class="solve-content">
        <h4 class="solve-title">解决方案</h4>
        <div class="solve-title-border"></div>
      </div>
      <!-- <div class="solve-operate">
        <div class="solve-img solve-img-left" @click="prevSolve">
          <img src="../../../assets/images/home/arrowLeftGrey.png" v-if="!prevStatus" alt="">
          <img src="../../../assets/images/home/arrowLeft.png" v-else alt="">
        </div>
        <div class="solve-img" @click="nextSolve">
          <img src="../../../assets/images/home/arrowRight.png" v-if="nextStatus" alt="">
          <img src="../../../assets/images/home/arrowRightGrey.png" v-else alt="">
        </div>
      </div> -->
    </div>
    <section style="width: 100%;" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
      <div :class="['solve-swiper',innerWidth * 1 < 1441?'':'common-swiper']" >
        <div :class="['solve-container',innerWidth * 1 < 1441?'':'common-container']" :style="'max-width:'+ innerWidth * 1 < 1441?'1100px':'1350px'">
          <div class="solve-center">
            <div class="solve-list"  @click="productHandle('farm')">
              <div class="solve-list-img">
                <img src="../../../assets/images/home/solveFarm.png" alt="">
              </div>
              <div class="solve-text">
                <h4>智慧农业</h4>
                <img src="../../../assets/images/home/more.png" alt="">
              </div>
            </div>
            <div class="solve-list"  @click="productHandle('forestry')">
              <div class="solve-list-img">
                <img src="../../../assets/images/home/solveLinye.png" alt="">
              </div>
              <div class="solve-text">
                <h4>智慧林业</h4>
                <img src="../../../assets/images/home/more.png" alt="">
              </div>
            </div>
            <div class="solve-list" @click="productHandle('water')">
              <div class="solve-list-img">
                <img src="../../../assets/images/home/solveWater.png" alt="">
              </div>
              <div class="solve-text">
                <h4>智慧水利</h4>
                <img src="../../../assets/images/home/more.png" alt="" >
              </div>
            </div>
            <div class="solve-list"  @click="productHandle('animal')">
              <div class="solve-list-img">
                <img src="../../../assets/images/home/solveAnimal.png" alt="">
              </div>
              <div class="solve-text">
                <h4>智慧牧业</h4>
                <img src="../../../assets/images/home/more.png" alt="">
              </div>
            </div>
            <div class="solve-list" @click="productHandle('computer')">
              <div class="solve-list-img">
                <img src="../../../assets/images/home/solveBusiness.png" alt="">
              </div>
              <div class="solve-text">
                <h4>智慧商业</h4>
                <img src="../../../assets/images/home/more.png" alt="" >
              </div>
            </div>
            <div class="solve-list" @click="productHandle('code')">
              <div class="solve-list-img">
                <img  src="../../../assets/images/home/solveCode.png" alt="">
              </div>
              <div class="solve-text">
                <h4>职业教育</h4>
                <img src="../../../assets/images/home/more.png" alt="" >
              </div>
            </div>
            <div class="solve-list"  @click="productHandle('sxj')">
              <div class="solve-list-img">
                <img src="../../../assets/images/home/solveCount.png" alt="">
              </div>
              <div class="solve-text">
                <h4>数字电商</h4>
                <img src="../../../assets/images/home/more.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="solve-img" id="solve-img-left" @click="prevSolve">
          <img src="../../../assets/images/home/arrowLeftGrey.png" v-if="!prevStatus" alt="">
          <img src="../../../assets/images/home/arrowLeft.png" v-else alt="">
        </div>
        <div class="solve-img" id="solve-img-right" @click="nextSolve">
          <img src="../../../assets/images/home/arrowRight.png" v-if="nextStatus" alt="">
          <img src="../../../assets/images/home/arrowRightGrey.png" v-else alt="">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clickIndex: 1,
      moveIndex: 1,
      solveLength: null,
      nextStatus: true,
      prevStatus: false,
      innerWidth:1920
    }
  },
  methods: {
    // 向后
    nextSolve() {
      let clickNum = this.innerWidth * 1 < 1441?2:3
      if (this.clickIndex * 1 <= this.solveLength - clickNum) {
        this.nextStatus = true
        this.prevStatus = true
        // 计算当前点击次数 点击一次向左轮播一个 共七个 最多点击四次
        let solveCenter = document.querySelector('.solve-center')
        solveCenter.style.left = -460 * this.moveIndex + 'px'
        solveCenter.style.transition = 'left 1.5s'
        this.clickIndex++
        this.moveIndex++
        if (this.clickIndex > this.solveLength - clickNum) {
          this.nextStatus = false
        }
      } else {
        return false
      }
    },
    // 向前
    prevSolve() {
      if (this.clickIndex * 1 > 1) {
        this.prevStatus = true
        this.nextStatus = true
        // 计算当前点击次数 点击一次向左轮播一个 共七个 最多点击四次
        let solveCenter = document.querySelector('.solve-center')
        solveCenter.style.left = -460 * (this.moveIndex - 2) + 'px'
        solveCenter.style.transition = 'left 1.5s'
        this.clickIndex--
        this.moveIndex--
        if (this.clickIndex * 1 <= 1) {
          this.prevStatus = false
        }
      } else {
        return false
      }
    },
    // 了解更多
    productHandle(type) {
      this.$router.push({
        path: '/product',
        query: {
          type
        }
      })
    },
    // 鼠标移入事件
    handleMouseEnter() {
      let swiperLeft = document.getElementById('solve-img-left')
      let swiperRight = document.getElementById('solve-img-right')
      swiperLeft.style.display = 'block'
      swiperRight.style.display = 'block'
    },
    // 鼠标移出事件
    handleMouseLeave() {
      let swiperLeft = document.getElementById('solve-img-left')
      let swiperRight = document.getElementById('solve-img-right')
      swiperLeft.style.display = 'none'
      swiperRight.style.display = 'none'
    }
  },
  created(){
    this.innerWidth = window.innerWidth
    // console.log(this.innerWidth)
  },
  mounted() {
    let solveList = [...document.getElementsByClassName('solve-list')]
    this.solveLength = solveList.length
  },
  beforeDestroy() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.solve {
  width: 100vw;
  // height: 854px;
  position: relative;
  // padding-bottom: 43px;
}

.solve-bg {
  width: 100vw;
  height: 854px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../../assets/images/home/solveBg.png');
  background-size: 100% 100%;
}

.solve-header {
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  align-items: baseline;

  .solve-content {
    flex: 1;
  }

  // .solve-operate {
  //   width: 105px;
  //   display: flex;
  // }
}

.solve-title {
  font-size: 36px;
  font-weight: 800;
  color: #333333;
  margin-top: 97px;
  margin-bottom: 0;
}

.solve-title-border {
  width: 41px;
  height: 2px;
  background: #d00001;
  margin: 12px auto 66px;
}

.solve-swiper {
  height: 440px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;

  .solve-img {
    width: 70px;
    height: 440px;
    line-height: 440px;
    text-align: center;
    cursor: pointer;

    // position: relative;
    // z-index: 99;
    img {
      width: 55px;
      height: 55px;
    }
  }

  #solve-img-left {
    // margin-right: 17px;
    position: absolute;
    top: 50%;
    left: -70px;
    transform: translateY(-50%);
    display: none;
  }

  #solve-img-right {
    position: absolute;
    top: 50%;
    right: -70px;
    transform: translateY(-50%);
    display: none;
  }
}
.common-swiper{
  max-width: 1350px;
}

.solve-container {
  height: 440px;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .solve-center {
    height: 440px;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    left: 0;
  }

  .solve-list {
    height: 440px;
    margin-right: 30px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
  }
  .solve-list-img{
    height: 300px;
    overflow: hidden;
    img{
    transition: transform 0.2s ease-in-out;
    }
  }
  .solve-list-img img:hover{
    transform: scale(1.1);
  }

  .solve-text {
    width: 430px;
    height: 140px;
    text-align: left;
    padding-top: 28px;
    padding-left: 30px;
    background: #FAFAFA;

    h4 {
      font-size: 28px;
      color: #252525;
      font-weight: 500;
      margin-bottom: 33px;
    }

    img {
      cursor: pointer;
    }
  }
}
.common-container{
  max-width: 1350px;
}

// .solve-swiper::after {
//   content: '';
//   display: block;
//   width: 70px;
//   height: 100%;
//   background: rgba(0, 0, 0, .1);
//   position: absolute;
//   // z-index: 99;
//   left: -70px;
//   top: 0;
// }
</style>