<template>
  <div class="core">
    <h4 class="core-title">核心技术</h4>
    <div class="core-title-border"></div>
    <section class="core-container">
    </section>
  </div>
</template>

<script>
export default {
data(){
  return{

  }
}
}
</script>

<style lang="scss" scoped>
.core-title {
  font-size: 36px;
  font-weight: 800;
  color: #333333;
  margin-top: 94px;
  margin-bottom: 0;
}

.core-title-border {
  width: 41px;
  height: 2px;
  background: #d00001;
  margin: 12px auto 66px;
}
.core-container{
  width: 100vw;
  height: 22.4vw;
  background-image: url('../../../assets/images/home/core.png');
  background-size: 100% 100%;
  overflow: hidden;
  h2{
    font-size: 40px;
    color: #FFFFFF;
    margin-top: 82px;
    font-weight: 400;
  }
}
</style>