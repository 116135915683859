<template>
  <div class="partners">
    <h4 class="partners-title">合作伙伴</h4>
    <div class="partners-title-border"></div>
    <section class="partners-container">

    </section>
  </div>
</template>

<script>
export default {
data(){
  return{

  }
}
}
</script>

<style lang="scss" scoped>
.partners-title {
  font-size: 36px;
  font-weight: 800;
  color: #333333;
  margin-top: 93px;
  margin-bottom: 0;
}

.partners-title-border {
  width: 41px;
  height: 2px;
  background: #d00001;
  margin: 12px auto 66px;
}
.partners-container{
  width: 100vw;
  height: 34.4vw;
  background-image: url('../../../assets/images/home/partners.png');
  background-size: 100% 100%;
}
</style>