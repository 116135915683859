<template>
  <div class="product">
    <h4 class="product-title">产品</h4>
    <div class="product-title-border"></div>
    <ul class="product-container">
      <li class="product-container-li" @click="getMore('smartFarm')">
        <img src="../../../assets/images/home/productFarm.png" alt="">
        <div class="product-container-title">
          <h5>农业产品</h5>
        </div>
      </li>
      <li class="product-container-list" @click="getMore('forestry')">
        <img src="../../../assets/images/home/productForestry.png" alt="">
        <div class="product-container-title">
          <h5>林业产品</h5>
        </div>
      </li>
      <li class="product-container-li" @click="getMore('water')">
        <img src="../../../assets/images/home/productWater.png" alt="">
        <div class="product-container-title">
          <h5>水利产品</h5>
        </div>
      </li>
      <!-- style="width: 46.3%;" -->
      <li class="product-container-list" @click="getMore('animal')">
        <img src="../../../assets/images/home/productAnimal.png" alt="">
        <div class="product-container-title">
          <h5>牧业产品</h5>
        </div>
      </li>
      <li class="product-container-li product-container-bottom" @click="getMore('computer')">
        <img src="../../../assets/images/home/productComputer.png" alt="">
        <div class="product-container-title">
          <h5>互联网产品</h5>
        </div>
      </li>
      <li class="product-container-li product-container-bottom" @click="getMore('code')">
        <img src="../../../assets/images/home/productCode.png" alt="">
        <div class="product-container-title">
          <h5>爱扣钉-IT培训教育</h5>
        </div>
      </li>
      <li class="product-container-bottom product-container-list" @click="getMore('sxj')">
        <img src="../../../assets/images/home/productSxj.png" alt="">
        <div class="product-container-title">
          <h5>社享家-电商社区服务平台</h5>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    getMore(type) {
      this.$router.push({
        path: '/' + type
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.product-title {
  font-size: 36px;
  font-weight: 700;
  color: #333333;
  margin-top: 81px;
  margin-bottom: 0;
}

.product-title-border {
  width: 41px;
  height: 2px;
  background: #d00001;
  margin: 13px auto 66px;
}

.product-container {
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      object-fit: cover;
    }

    h5 {
      color: #ffffff;
      font-size: 28px;
      font-weight: 500;
      position: absolute;
      bottom: 23px;
      left: 29px;
    }
  }

  .product-container-li {
    margin-right: 1.7%;
    overflow: hidden;
    height: 430px;

    // img {
    //   transition: transform 0.2s ease-in-out;
    // }

    // img:hover {
    //   transform: scale(1.1);
    // }

  }

  .product-container-bottom {
    margin-bottom: 0;
  }

  .product-container-list {
    height: 430px;
    overflow: hidden;

    // img {
    //   transition: transform 0.2s ease-in-out;
    // }

    // img:hover {
    //   transform: scale(1.1);
    // }
  }

  .product-container-title {
    width: 100%;
    height: 80px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .product-container-li:hover,.product-container-list:hover {
    .product-container-title {
      background: rgba(0, 0, 0, 0.4);
    }
    img {
      transition: transform 0.3s ease-in-out;
      transform: scale(1.1);
    }

  }
}

@media (max-width: 1349px) {
  .product-container {
    width: 70.3%;

    li {
      width: 22% !important;
      margin-right: 1.7%;
      margin-bottom: 30px;
    }

    h5 {
      font-size: 14px !important;
      left: 8px !important;
    }

  }

  .product-container li:nth-child(1) {
    width: 46.3% !important;

  }

  .product-container li:nth-child(4) {
    width: 46.3% !important;

  }

  .product-container-bottom {
    margin-bottom: 30px !important;
  }
}
</style>