<template>
  <div class="contact">
    <h4 class="contact-title">联系我们</h4>
    <div class="contact-title-border"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.contact-title {
  font-size: 36px;
  font-weight: 800;
  color: #333333;
  margin-top: 97px;
  margin-bottom: 0;
}

.contact-title-border {
  width: 41px;
  height: 2px;
  background: #d00001;
  margin: 12px auto 71px;
}
</style>